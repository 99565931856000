.z-0{
    z-index: -1 !important;
}
.custom_margin_country_hero{
margin-top: 107px;
}
.color_primary{
    color: #B88314;
}
.color_light_black {
    color: #2C2C2C;
}
.custom_translate_country_img{
    transform: translateY(150px);
}
.font_17{
font-size: 17px;
line-height: 21.22px;
}
.image-brightness{
    filter: brightness(0.5);
}