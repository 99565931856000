.custom_top_margin {
    margin-top: 107px;
}

.custom_translate_btn {
    transform: translate(-50%, 50%);
}

.country_btn {
    background-color: #B88314;
    border: 2px solid white;
    box-shadow: 0px 60px 31.7px -34px #B8831461;
    color: white;
    padding: 5px 100px;
    border-radius: 5px;
}
.bg_primary{
    background-color: #FFE4AC;
}

.translate_middle_y{
    transform: translateY(80%);
}
.z-index{
    z-index: -1;
}
.z-index-1{
    z-index: 1;
}
.margin_custom{
    margin-top: 24px;
}
.bg_dark_brown{
    background-color: #B88314;
}
.translate-middle_y{
    transform: translateY(50%);
}
select:focus-visible {
    outline: none !important;
}
.custom_input_width{
    width: 150px;
outline: none;
}
.custom_input_width::placeholder{
    color: black;
    font-weight: 700;
    font-size: 21px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.bg-light{
    background-color: #FFFAF0;
}

/* COUNTRY WEATHER */
.weather-div {
    background-color: #1d2837;
    box-shadow: 0px 8.58px 28.6px 0px #00000033;
    border: 1.43px solid #ffffff26;
  }
  .opacity-88 {
    opacity: 88%;
  }
  .opacity-68 {
    opacity: 68%;
  }
  .hr_line {
    height: 1px;
    background: #ffffff14;
  }
  .weather-report-div {
    max-width: 700px;
    overflow-x: auto;
    overflow-y: hidden;
  }

/* clock */
.clock {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    color: #444;
    text-align: center;
  }
  
  .clock::after {
    background: #aaa;
    content: "";
    width: 12px !important;
    height: 12px !important;
    max-width: 12px !important;
    max-height: 12px !important;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
  }

  .clock:hover .clock::after{
    width: 12px !important;
    height: 12px !important;
  }
  
  .hour_hand {
    position: absolute;
    width: 4px;
    height: 60px;
    background: #222;
    top: 29%;
    left: 50%;
    border-radius: 8px;
    transform-origin: bottom;
  }
  
  .min_hand {
    position: absolute;
    width: 4px;
    height: 92px;
    background: #fc3e55d6;
    top: 18.5%;
    left: 50%;
    border-radius: 8px;
    transform-origin: bottom;
  }
  
  .clock span {
    position: absolute;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
  
  .twelve {
    top: 10px;
    left: 46%;
  }
  
  .one {
    top: 10%;
    right: 26%;
  }
  
  .eleven {
    top: 10%;
    left: 26%;
  }
  
  .two {
    top: 25%;
    right: 10%;
  }
  
  .three {
    right: 10px;
    top: 46%;
  }
  
  .four {
    right: 30px;
    top: 67%;
  }
  
  .five {
    right: 78px;
    top: 80%;
  }
  
  .six {
    bottom: 10px;
    left: 50%;
  }
  
  .seven {
    left: 80px;
    top: 82%;
  }
  
  .eight {
    left: 30px;
    top: 67%;
  }
  
  .nine {
    left: 10px;
    top: 46%;
  }
  
  .ten {
    top: 25%;
    left: 10%;
  }

  .filter{
    filter: brightness(0.8);
  }

  .overlay1{
    width: 100%;
    height: 100%;
    background-color: BLACK;
    opacity: 0.2;
    left: 0;
    top: 0;
    border-radius: 14px;
  }

  .translate_middle-y{
    transform: translateY(-50%) !important;
  }
.custom_width{
  width: 100px;
}
@media (max-width:576px) {
    .country_btn {
       font-size: 20px !important;
        padding: 5px 50px;
    }
}
@media (max-width:786px) {
    .country_btn {
       font-size: 25px !important;
        padding: 5px 50px;
    }
    .translate_middle-y{
      transform: translateY(-10%) !important;
    }
    
    .translate-middle_y{
      transform: translateY(20%) !important;
  }
  .custom_width{
    width: 50px;
  }
}

@media (min-width:768px) {
    .margin_custom{
        margin-top: -48px;
    }
}