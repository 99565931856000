* {
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  box-sizing: border-box;
}

.footer_background {
  background-color: #202f1f;
}

.opacity_color {
  color: rgba(255, 255, 255, 1);
}

.custom_border {
  border: 1px solid rgba(61, 90, 59, 1);
}

.color_gary {
  color: #686a68 !important;
}

.custom_bg {
  background: rgba(255, 244, 220, 1);
}

.contact_card {
  width: 622px;
  box-shadow: 0px 158px 90.6px -120px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
}
.about_card {
  width: 622px;
  box-shadow: 0px 158px 90.6px -120px rgba(0, 0, 0, 0.25);
  background: #fffaf0;
  border-radius: 40px 5px 5px 5px;
  padding-left: 100px !important;
}

.custom_input {
  border: 0.79px solid rgba(184, 131, 20, 1) !important;
  outline: none;
}

::placeholder {
  color: rgba(144, 144, 144, 1);
}

.send_btn {
  background: rgba(184, 131, 20, 1);
}
.about_position {
  left: 300px;
}

.custom_linehight {
  line-height: 28.81px !important;
}

.image_margintop {
  margin-top: -130px !important;
  z-index: 123 !important;
  position: relative !important;
}

.custom_zindex {
  z-index: 1234567;
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 35px;
}

.burger div {
  width: 35px;
  height: 1px;
  background-color: #b88314;
  border: 2px solid #b88314;
  transition: 0.3s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.burger.active div:nth-child(1) {
  transform: rotate(-42deg) translate(-6px, 6px);
}

.burger.active div:nth-child(2) {
  opacity: 0;
}

.burger.active div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #202f1f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transition: all 400ms ease-in-out;
}

.after_line::after {
  content: "";
  width: 0;
  border: 1px solid #b88314;
  background-color: #b88314;
  position: absolute;
  bottom: -5px;
  display: block;
  transition: all 400ms ease-in-out;
  opacity: 0;
}

.position-relative:hover::after {
  width: 100% !important;
  opacity: 1;
}

.cross_icon {
  top: 20px;
  right: 20px;
}

.bg_light_brown {
  background: rgba(184, 131, 20, 0.31);
}

.image_form {
  background-image: url(../src/assets/images/homepage_form_img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero_bg_image {
  background-image: url(../src/assets/images/hero_bg_img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg_newleeter {
  background-image: url(../src/assets/images/section-img-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cursor_pointer {
  cursor: pointer;
}

.overlay {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 244, 220, 0.728);
  border-radius: 0px 0px 12px 12px;
  opacity: 0;
  z-index: 123;
  transition: all 400ms ease-in-out;
}

.position-relative:hover .overlay {
  opacity: 1;
}

.custom_pading {
  padding: 80px 20px 100px 80px !important;
}

.img_fluid {
  height: 50px !important;
  margin-top: -2px !important;
}

.line_hight {
  line-height: 28.81px !important;
}

.areas {
  background-color: #ffe4ac;
}

.areas_margin_bottom {
  margin-bottom: -200px !important;
}
@media (min-width: 1200px) {
  .image_margin {
    margin-left: 120px !important;
    z-index: 1213 !important;
    position: relative !important;
  }
}
@media (min-width: 992px) {
  .custom_top_space {
    margin-top: -160px !important;
  }
  .contact_position {
    top: 0% !important;
  }
  .margin_bottom_space {
    margin-bottom: -35px !important;
  }
  .custom_hight {
    height: 500px !important;
  }
}
@media (min-width: 768px) {
  .custom_color {
    color: #b88314 !important;
  }
  .Clock_image {
    width: 244px;
  }
}
@media (max-width: 1199px) {
  .image_margin {
    margin-left: 100px !important;
    z-index: 1213 !important;
    position: relative !important;
  }
}
@media (max-width: 991px) {
  .contact_position {
    top: 25% !important;
    transform: translateY(-50%) !important;
  }
  .custom_top_space {
    margin-top: 50px !important;
  }
  .image_margin {
    margin-left: 0px !important;
    z-index: 0 !important;
    position: relative !important;
  }
  .image_margintop {
    margin-top: 0px !important;
  }
  .about_card {
    padding-left: 30px !important;
  }
  .about_position {
    left: 150px;
  }
  .custom_hight {
    height: 400px !important;
  }
}

@media screen and (max-width: 767px) {
  .navmanu {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .sidebar {
    width: 100%;
    transition: all 400ms ease-in-out;
  }
  .custom_color {
    color: #000 !important;
  }
  .Clock_image {
    width: 150px;
  }
}
