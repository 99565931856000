@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Inter:wght@100..900&display=swap");

.ff_inter {
  font-family: "Inter", sans-serif;
}
.ff_heebo {
  font-family: "Heebo", sans-serif;
}
body {
  font-family: "Heebo", sans-serif !important;
  background-color: #fff4dc !important;
  color: #393939 !important;
}
:root {
  --fs-4xsm: 13px;
  --fs-3xsm: 14px;
  --fs-2xsm: 15px;
  --fs-xxsm: 16px;
  --fs-xsm: 17px;
  --fs-sm: 18px;
  --fs-md: 19px;
  --fs-lg: 21px;
  --fs-xl: 22px;
  --fs-xxl: 24px;
  --fs-2xl: 28px;
  --fs-3xl: 30px;
  --fs-4xl: 33px;
  --fs-5xl: 34px;
  --fs-6xl: 36px;
  --fs-7xl: 40px;
  --fs-8xl: 41px;
  --fs-9xl: 45px;
  --bg-primary: #ffe4ac;
  --color-primary: #b88314;
}

.fs_13 {
  font-size: var(--fs-4xsm);
}
.fs_14 {
  font-size: var(--fs-3xsm);
  line-height: 17.45px;
}
.fs_15 {
  font-size: var(--fs-2xsm);
  line-height: 18.93px;
}
.fs_16 {
  font-size: var(--fs-xxsm);
  line-height: 18.93px;
}
.fs_17 {
  font-size: var(--fs-xsm);
  line-height: 28.81px;
}
.fs_18 {
  font-size: var(--fs-sm);
}
.fs_19 {
  font-size: var(--fs-md);
}
.fs_21 {
  font-size: var(--fs-lg);
  line-height: 25.1px;
}
.fs_22 {
  font-size: var(--fs-xl);
  line-height: 26.75px;
}
.fs_24 {
  font-size: var(--fs-xxl);
}
.fs_28 {
  font-size: var(--fs-2xl);
}
.fs_30 {
  font-size: var(--fs-3xl);
  line-height: 37.45px;
}
.fs_33 {
  font-size: var(--fs-4xl);
  line-height: 40.99px;
}
.fs_34 {
  font-size: var(--fs-5xl);
}
.fs_36 {
  font-size: var(--fs-6xl);
}
.fs_40 {
  font-size: var(--fs-7xl);
  line-height: 48.28px;
}
.fs_41 {
  font-size: var(--fs-8xl);
  line-height: 50.87px;
}
.fs_45 {
  font-size: var(--fs-9xl);
}
.fw-medium {
  font-weight: 500;
}

.top-navbar-container {
  z-index: 999;
}
.py-23 {
  padding-top: 23px;
  padding-bottom: 23px;
}

.navbar-link {
  font-size: 17.56px;
  line-height: 21.43px;
  text-transform: capitalize;
}

.bg-theme {
  background-color: rgba(255, 244, 220, 0.973) !important;
}
.bg-theme-primary {
  background-color: #fff7e6 !important;
}
.bg-section-primary {
  background-color: var(--bg-primary) !important;
}
.bg-section {
  background-color: rgba(255, 244, 220, 1);
}
.text-brown {
  color: #b88314;
}
.banner-transparent-bg {
  /* background: linear-gradient(0deg, rgba(255,244,220,1), rgba(255,244,220,0)); */
  margin-top: 150px;
}
.form-control {
  font-size: 13px !important;
  line-height: 16px !important;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.rounded-right-top {
  border-radius: 0 50px 0 0;
}
.rounded-left-top {
  border-radius: 50px 0 0 0;
}

.shape-long-rect {
  width: 40px;
  height: 110px;
  background-color: var(--btn-primary);
}
.me-n {
  margin-right: -100px;
}
.border-brown {
  border: 1px solid var(--color-primary) !important;
}
.z-index-10 {
  z-index: 10;
}


.z_index{
  z-index:345678 !important ;
}

@media (min-width: 768px) {
  .skew-left {
    margin: 0 0 0 0;
    transform: skew(-2deg);
  }
}
@media (max-width: 576px) {
  :root {
    --fs-4xsm: 13px;
    --fs-3xsm: 14px;
    --fs-2xsm: 15px;
    --fs-xxsm: 16px;
    --fs-xsm: 17px;
    --fs-sm: 18px;
    --fs-md: 19px;
    --fs-lg: 21px;
    --fs-xl: 22px;
    --fs-xxl: 24px;
    --fs-2xl: 28px;
    --fs-3xl: 30px;
    --fs-4xl: 33px;
    --fs-5xl: 34px;
    --fs-6xl: 36px;
    --fs-7xl: 40px;
    --fs-8xl: 34px;
    --fs-9xl: 45px;
  }
}
